class Authentication {
  constructor() {
    this.article_id = window.location.pathname;
    this.validate();
  }

  showContent() {
    const container = document.querySelector(".article__body");
    fetch(
      `/api/news/${this.get_article_id()}/content?language=${this.get_language()}`,
    ).then((data) => {
      console.log(data);
      data.text().then((content) => (container.innerHTML = content));
    });
    let footer_bylines = document.querySelector("footer.article__bylines");
    footer_bylines.classList.add("show");
  }

  get_article_id() {
    let urlFragments = location.pathname.split("/");
    if (urlFragments[1] == "news") {
      return urlFragments[2];
    }
    return urlFragments[3];
  }

  is_article() {
    let urlFragments = location.pathname.split("/");
    if (urlFragments.includes("news")) {
      return true;
    }
    return false;
  }

  get_language() {
    let urlFragments = location.pathname.split("/");
    if (urlFragments[1] == "news") {
      return "en";
    }
    return urlFragments[1];
  }

  validate() {
    // Validating
    console.log("Validating");
    fetch(
      "https://authenticate-dev.montelnews.com/validate?applicationId=dd44303d-539a-4e81-9fb8-029d9b83e2f3",
      { credentials: "include" },
    ).then((res) => {
      if (res.status === 200) {
        // Validated
        document
          .querySelector(".mobi-logout")
          .classList.remove("button--hidden");
        document
          .querySelector("#header-sign-up")
          .classList.add("button--hidden");
        document.querySelector("#logout").classList.remove("button--hidden");
        document.querySelector("#login").classList.add("button--hidden");
        document.querySelector(".avatar").classList.remove("avatar--hidden")
        const trials = document.querySelectorAll(".nav-trial");
        if (trials.length > 0) {
          trials.forEach((trial) => trial.remove());
        }
        if (this.is_article()) {
          this.showContent();
        }
      } else {
        // Try to refresh token
        fetch(
          "https://authenticate-dev.montelnews.com/refresh?applicationId=dd44303d-539a-4e81-9fb8-029d9b83e2f3",
          { credentials: "include" },
        ).then((res) => {
          if (res.status === 200) {
            // Has refresh token, validate again
            fetch(
              "https://authenticate-dev.montelnews.com/validate?applicationId=dd44303d-539a-4e81-9fb8-029d9b83e2f3",
              { credentials: "include" },
            ).then((res) => {
              if (res.status === 200) {
                // Logged in
                // document
                //   .querySelector("#header-sign-up")
                //   .classList.add("button--hidden");
                document
                  .querySelector("#logout")
                  .classList.remove("button--hidden");
                document
                  .querySelector("#login")
                  .classList.add("button--hidden");
                const trials = document.querySelectorAll(".nav-trial");
                trials.forEach((trial) => trial.remove());
                this.showContent();
              }
            });
          } else {
            console.log("Can't refresh, not logged in")
            // Can't refresh, not logged in
            const logout = document.querySelector("#logout");
            if (logout) {
              logout.classList.add("button--hidden");
            }
            const login = document.querySelector("#login");
            if (login) {
              login.classList.remove("button--hidden");
            }
          }
        });
      }
    });
  }

  freeArticle(id) {
    fetch(`/api/article/${id}/access`).then((res) => {
      if (res.status == 200) {
        res.text().then((data) => {
          if (data === "FREE") {
            return true;
          }
        });
      }
    });
    return false;
  }
}

export default Authentication;

class Search {

  constructor() {
    this.input = document.querySelector('#search-input')
    if (this.input) {
      const params = new URLSearchParams(window.location.search)
      let language = window.location.pathname.split("/")[1]
      this.query = params.get('q')
      this.clearButton = document.querySelector('#search-clear')
      this.searchButton = document.querySelector('#search-button')
      this.loader = document.querySelector(".search__loader")
      this.endCursor = null
      this.language = this.getLanguage()
      // this.listenForClear()
      // this.listenForInput()
      this.listenForPagination()
    }
  }

  listenForClear() {
    this.clearButton.addEventListener('click', (ev) => {
      ev.preventDefault()
      this.input.value = ""
      this.clearButton.classList.add('search__clear--hidden')
      this.searchButton.href = "#"
      this.input.classList.remove('open')
    })
  }

  getLanguage() {
    return window.location.pathname.split('/')[1]
  }

  listenForInput() {
    let lastPress
    let intervals = []
    this.input.addEventListener('keyup', () => {
      if (this.input.value.length === 0) {
        this.clearButton.classList.add('search__clear--hidden')
        this.loader.classList.add('search__loader--hidden')
        this.clearResults()
      }
      if (this.input.value.length > 0) {
        this.clearResults()
        this.clearButton.classList.remove('search__clear--hidden')
        this.loader.classList.remove('search__loader--hidden')
        lastPress = Date.now()
        this.input.classList.add('open')
      }

      // Wait a few ticks after last clicked button, then search
      intervals.push(setInterval(() => {
        if (Date.now() - lastPress >= 1000) {
          this.search()
          intervals.forEach(interval => clearInterval(interval))
        }
      }, 500))
    })

  }

  search() {
    const resultContainer = document.querySelector('.search__results ul')
    const template = document.querySelector('#search-result-item')
    const value = this.input.value
    if (!value) console.log("Search: No value")
    let query = '/api/search?q=' + value
    if (this.language) {
      query += `&language=${this.language}`
    }

    // Avoid duplicating list
    const items = resultContainer.querySelectorAll('.search__result-item')
    items.forEach(item => item.remove())

    fetch(query)
    .then(res => res.json()
    .then(data => {
      if (data && data.totalCount > 0) {
        const hits = data.totalCount
        const max = 10;
        for (let i = 0; i < max; i++) {
          const clone = template.content.cloneNode(true)
          let link = clone.querySelector('.search__result-link')

          let slug = this.slugify(data.items[i].title)
          link.href = `/${this.language}/news/${data.items[i].id}/${slug}`
          link.textContent = data.items[i].title
          resultContainer.append(clone)
        }
        const url = '/en/search?q=' + value
        this.addFooterItem(url, hits)
        this.loader.classList.add('search__loader--hidden')
      } else {
        // @TODO handle No results
        console.log("Search: No results")
      }
    })
    )
  }

  slugify(str) {
    str = str.toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '')
      .replace('æ', 'ae')
      .replace('ø', 'o')
      .replace('å', 'a')
    return str
  }

  addFooterItem(url, count) {
    const resultContainer = document.querySelector('.search__results ul')
    const template = document.querySelector('#search-results-link')
    let clone = template.content.cloneNode(true)
    let anchor = clone.querySelector('a')
    anchor.href = url
    const counter = clone.querySelector('#search-count')
    counter.innerHTML = count
    resultContainer.append(clone)
  }

  clearResults() {
    const results = document.querySelectorAll('.search__result-item')
    results.forEach(result => result.remove())
    this.input.classList.remove('open')
  }

  listenForPagination() {
    let moreButton = document.querySelector('#search-fetch')
    if (moreButton) {
      moreButton.addEventListener('click', (ev) => {
        const endCursor = document.querySelector('#search-cursor').getAttribute('data-cursor')
        const template = document.querySelector('#page-search-item')
        const container = document.querySelector('.search-results__list')
        fetch(`/api/search?Language=${this.language}&q=${this.query}&After=${endCursor}&SortBy=Date&SortDirection=Desc&FuzzyMatching=True`)
        .then(res => {
          if (res.status == 200) {
            res.json().then(data => {
              const length = data.items.length
              if (length < 10) {
                document.querySelector('#search-fetch').remove()
              }
              for (let i = 0; i < length; i++) {
                const clone = template.content.cloneNode(true)
                const slug = this.slugify(data.items[i].title)
                const linkEl = clone.querySelector('.search-results__link')
                const titleEl = clone.querySelector('.search-results__title')
                const excerptEl = clone.querySelector('.search-results__excerpt')
                const dateEl = clone.querySelector('.search-results__published')
                excerptEl.innerHTML = " &mdash; " + data.items[i].excerpt.replace(/^(.{200}[^\s]*).*/, "$1") + "..."
                linkEl.href = `news/${data.items[i].id}/${slug}`
                titleEl.textContent = data.items[i].title
                dateEl.textContent = new Date(data.items[i].publicationDate).toLocaleDateString("no-NB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric"
                })

                container.appendChild(clone)
              }
              // update endCursor
              document.querySelector('#search-cursor').setAttribute('data-cursor', data.endCursor)
            })
          }
        })
      })
    }
  }

}

export default Search